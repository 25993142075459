import PaymentApi from '@/api/payment.api';

const module = {
  state: {
    step: 1,
    paymentId: '',
    typeCard: '',
    amount: '',
    paymentStatus: '', // see config file with list of statuses
    ndc: '',
    integrity: '',
    hyperpay_url: '',
    transaction: {},
    grandTotal: '',
    reservationId: ''
  },
  mutations: {
    /**
     * RESET_CHECKOUT_FIELDS
     * @param {type Object state} state
     * @param {type Array} fields
     */
    RESET_CHECKOUT_FIELDS (state, fields) {
      fields.forEach((fieldItem) => {
        if (state[fieldItem]) state[fieldItem] = '';
      });
    },
    SET_STEP (state, step) {
      state.step = step;
    },
    SET_NDC (state, ndc) {
      state.ndc = ndc;
    },
    SET_INTEGRITY (state, integrity) {
      state.integrity = integrity;
    },
    SET_HYPERPAY_URL (state, hyperpay_url) {
      state.hyperpay_url = hyperpay_url;
    },
    SET_AMOUNT (state, amount) {
      state.amount = amount;
    },
    SET_TYPE_CARD (state, card) {
      state.typeCard = card;
    },
    SET_PAYMENT_STATUS (state, status) {
      state.paymentStatus = status;
    },
    SET_PAYMENT_id (state, id) {
      state.paymentId = id;
    },
    SET_TRANSACTION_INFO (state, transaction) {
      state.transaction = transaction;
    },
    SET_GRAND_TOTAL (state, total) {
      state.grandTotal = total;
    },
    SET_RESERVATION_ID (state, id) {
      state.reservationId = id;
    }
  },
  actions: {
    prepareCheckout: async ({ commit, rootState }, data) => {
      return await PaymentApi.prepareCheckout(rootState.user.userSpace, data);
    },
    prepareCheckoutSession: async ({ commit, rootState }, data) => {
      return await PaymentApi.prepareCheckout(rootState.user.userSpace, data);
    },
    retrievingPaymentResult: async ({ commit, rootState }, id) => {
      try {
        const response = await PaymentApi.retrievingPaymentResult(rootState.user.userSpace, id);
        await commit('SET_PAYMENT_STATUS', response.data.transaction_status);
        await commit('SET_RESERVATION_ID', response.data?.reservation?.id);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    /**
     * @param {Number} id Payment ID
     * @param {String} type 'payments' or 'withdrawals'
     * @returns {Promise<unknown>}
     */
    getTransactionDetails: async ({ commit, rootState }, payload) => {
      try {
        const response = await PaymentApi.getTransactionDetails(rootState.user.userSpace, payload);
        const { data } = response;
        await commit('SET_GRAND_TOTAL', data.amount);
        await commit('SET_TRANSACTION_INFO', {
          id: data.id,
          transaction_id: data?.response?.id || data?.id,
          amount: data.amount,
          transaction_date: data.transaction_date || data.date,
          labors: data.labors
        });

        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    },
    getWithdrawalList: async ({ commit, rootState }, params) => {
      return PaymentApi.getWithdrawalList(rootState.user.userSpace, params);
    },
    getListGroupsWithLaborsIDs: async ({ rootState }, params) => {
      return await PaymentApi.getListGroupsWithLaborsIDs(rootState.user.userSpace, params);
    },
    issuingCertificates: async ({ rootState }, data) => {
      return await PaymentApi.issuingCertificates(rootState.user.userSpace, data);
    },
    setNdc: ({ commit }, ndc) => {
      commit('SET_NDC', ndc);
    },
    setIntegrity: ({ commit }, integrity) => {
      commit('SET_INTEGRITY', integrity);
    },
    setHyperpayUrl: ({ commit }, hyperpay_url) => {
      commit('SET_HYPERPAY_URL', hyperpay_url);
    },
    /**
     * resetCheckoutFields
     * @param {type Function state} commit
     * @param {type Array} fieldsArray
     */
    resetCheckoutFields: ({ commit }, fieldsArray) => commit('RESET_CHECKOUT_FIELDS', fieldsArray),
    setStep: ({ commit }, step) => commit('SET_STEP', step),
    setTypeCard: ({ commit }, card) => commit('SET_TYPE_CARD', card),
    setAmount: ({ commit }, amount) => commit('SET_AMOUNT', amount),
    setPaymentStatus: ({ commit }, status) => commit('SET_PAYMENT_STATUS', status),
    setPaymentId: ({ commit }, id) => commit('SET_PAYMENT_id', id)
  },
  getters: {
    step: (state) => state.step,
    typeCard: (state) => state.typeCard,
    paymentStatus: (state) => state.paymentStatus,
    paymentId: (state) => state.paymentId,
    ndc: (state) => state.ndc,
    integrity: (stage) => stage.integrity,
    hyperpayUrl: (stage) => stage.hyperpay_url,
    amount: (state) => state.amount,
    grandTotal: (state) => state.grandTotal,
    transaction: (state) => state.transaction,
    reservationId: (state) => state.reservationId
  }
};
export default module;
