import { getEnvironmentVar } from '@/utils/environments';

const hostTemp = window.location.hostname;

export const isDemo = hostTemp.indexOf('svp.demo') !== -1;
export const isStage = hostTemp.indexOf('svp.stage') !== -1;
export const isUat = hostTemp.indexOf('svp.uat') !== -1;
export const isProd = hostTemp.indexOf('pacc.sa') !== -1;
export const isLocal = hostTemp.indexOf('qiwa.info') !== -1;

export const HOST = getEnvironmentVar('HOST');
export const API_URL = getEnvironmentVar('API_URL');
export const ACTION_CABLE_URL = getEnvironmentVar('ACTION_CABLE_URL');
export const ZENDESK_KEY = getEnvironmentVar('ZENDESK_KEY');
export const GTM_KEY = getEnvironmentVar('GTM_KEY');
export const RECAPTCHA_KEY = getEnvironmentVar('RECAPTCHA_KEY');
export const MAP_API_KEY = getEnvironmentVar('MAP_API_KEY');
export const PAYMENT_API = getEnvironmentVar('PAYMENT_API');
export const SERVER_URL = getEnvironmentVar('SERVER_URL');
export const CERTIFICATE_PRICE = getEnvironmentVar('CERTIFICATE_PRICE');
export const isDevelopment = isDemo || isStage || isLocal;
export const isProduction = isUat || isProd;
export const WEB_SOCKET_CONSUMER = 'websocket'; // For Action Cable connection
export const WEB_SOCKET_CHANNEL = 'NotificationsChannel'; // For Action Cable connection

export const PER_PAGE = 10; // items per page for pagination

const defaultLang = localStorage.getItem('lang') || 'en'; // default language should be here
export const langs = [defaultLang, 'en', 'ar']; // default language should be first

export const permissionsNames = {
  center_owner: 'test_center_owner',
  legislator: 'legislator',
  labor: 'labor',
  assessor: 'assessor'
};

// visitor_space - default userSpaces (set on state.js)
export const userSpaces = {
  visitor_space: 'visitor_space',
  legislator: 'legislator_space',
  test_center_owner: 'test_center_owner_space',
  labor: 'individual_labor_space',
  assessor: 'assessor_space'
};

// Todo move to statuses.js file all status related enums
export const testCenterStatuses = {
  active: 'active',
  deleted: 'deleted',
  inactive: 'inactive'
};

export const legislatorStatuses = {
  approved: 'approved',
  rejected: 'rejected',
  deleted: 'deleted',
  suspended: 'suspended'
};

export const assessorStatuses = {
  firstReview: 'first_review',
  secondReview: 'second_review',
  active: 'active',
  approved: 'approved',
  rejectedByLegislator: 'rejected_by_legislator',
  rejected: 'rejected'
};

export const certificateStatuses = {
  valid: 'valid',
  expired: 'expired'
};

export const paymentStatusesTexts = {
  success: 'success',
  prepared_checkout: 'prepared checkout',
  failed: 'failed'
};

export const confirmModalSettings = {
  classes: 'confirm-modal__wrapper',
  height: 'auto',
  width: '500',
  scrollable: true
};

export const confirmIssueModalSettings = {
  classes: 'confirm-modal__wrapper',
  height: 'auto',
  width: '800',
  scrollable: true
};

export const editLaborModalSettings = {
  classes: 'confirm-modal__wrapper',
  height: 'auto',
  width: '800',
  scrollable: true
};

export const paramNames = {
  nationalId: 'national_id',
  country: 'country_id',
  city: 'city_id',
  cities: 'cities',
  name: 'name',
  passportNumber: 'passport_number',
  phoneNumber: 'phone_number',
  nationality: 'nationality_id',
  status: 'status',
  lastActiveDate: 'last_active_date',
  email: 'email',
  seatsAmount: 'available_seats',
  reservationsAmount: 'reservations',
  examStatus: 'status',
  dateTo: 'start_at_date_to',
  dateFrom: 'start_at_date_from',
  appliedOnTo: 'applied_on_to',
  appliedOnFrom: 'applied_on_from',
  category: 'category_id',
  id: 'id',
  assessorName: 'assessor_name',
  CNIC: 'cnic',
  countryCode: 'country_code',
  invoiceId: 'invoice_id',
  transactionDateFrom: 'transaction_date_from',
  transactionDateTo: 'transaction_date_to',
  transactionStatus: 'transaction_status',
  assessor: 'assessor_id',
  registrationMethod: 'registration_method'
};
