<template>
  <ul class="notification-list">
    <NotificationItem
      v-for="(item, index) in notifications_list"
      :key="index"
      :text="item.body"
      :date="item.created_at"
      :class="item.read ? 'read' : 'unread'"
      @click.native="openNotification(item)"
    />
    <InfiniteLoading @infinite="infiniteHandler">
      <div
        slot="no-more"
        class="py-1"
      >No more notifications</div>
      <div
        slot="no-results"
        class="py-1"
      >No more notifications</div>
    </InfiniteLoading>
  </ul>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters } from 'vuex';

import NotificationModal from '@/components/modals/NotificationModal';
import NotificationItem from '@/components/notifications/NotificationItem';
import websockets from '@/websockets';
import channels from '@/websockets/channels';

export default {
  name: 'NotificationList',
  components: {
    NotificationItem,
    InfiniteLoading
  },
  data () {
    return {
      params: {
        page: 1,
        per_page: 5
      }
    };
  },
  computed: {
    ...mapGetters(['notifications_list', 'not_read_count'])
  },
  beforeDestroy () {
    websockets.unsubscribeChannel(channels.notification);
    this.clearNotifications(); // Remove notifications list
  },
  async created () {
    try {
      const { data: { pagination } } = await this.getNotifications(this.params);
      this.params.page = pagination.next;

      this.websocketConnect();
    } catch (err) {
      console.log(err);
      this.$toasted.error('Something went wrong');
    }
  },
  methods: {
    ...mapActions(['getNotifications', 'pushNewNotification', 'concatWithNotifications', 'setNotReadCount', 'clearNotifications']),

    openNotification (messageItem) {
      this.$modal.show(NotificationModal, {
        title: 'newNotification',
        text: messageItem.body.message
      },
      {
        classes: 'notification-modal__wrapper',
        height: 'auto',
        width: '500',
        scrollable: true
      });
      if (this.not_read_count > 0 && !messageItem.read) this.setNotReadCount(this.not_read_count - 1);

      // Send to server
      websockets.sendMessage(channels.notification, { read: true, id: messageItem.id });

      messageItem.read = true;
    },

    async infiniteHandler ($state) {
      try {
        const { data: { pagination } } = await this.getNotifications(this.params);
        // get new notifications
        if (pagination.next) {
          this.params.page = pagination.next;
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (err) {
        console.log(err);
        this.$toasted.error('Something went wrong');
      }
    },
    websocketConnect () {
      websockets.createChannel(channels.notification, {
        received: (data) => {
          const { id, notification, created_at } = data;
          if (notification) {
            this.pushNewNotification(
                {
                  id,
                  body: notification,
                  created_at,
                  read: false
                }
            );

            this.setNotReadCount(this.not_read_count + 1);
          }
        }
      });
    }
  }
};
</script>
